import styled from 'styled-components'

export const Wrapper = styled.div`
  grid-column: 1/-1;
  padding: 60px 0;

  @media screen and (min-width: 1024px) {
    padding: 60px 0 120px;
  }
`

export const HeadWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  > div {
    margin-bottom: 36px;

    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
`
