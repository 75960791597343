import React, { useState } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import SideSection from '../partials/commons/SideSection'
import RegulatoryMenus from '../partials/Regulatory'

const PageRegulatory = (): JSX.Element => {
  const [toggleForm, setToggleForm] = useState(false)

  const toggle = (): void => {
    setToggleForm(true)
  }

  return (
    <Layout pageType="home" toggle={toggle}>
      <SEO
        title="Cora - Documentos Regulatórios"
        description="A Cora é uma conta digital desenvolvida para empreendedores como você. Simples, sem burocracia e sem taxas. Se você empreende, você é Cora!"
        linkCanonical="regulatorio"
      />
      <RegulatoryMenus />
      <SideSection
        toggleForm={toggleForm}
        setToggleForm={setToggleForm}
        idFinishForm="FormFinishedHome"
        pageType="home"
      />
    </Layout>
  )
}

export default PageRegulatory
