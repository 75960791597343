import React from 'react'

import Heading from '../../../components/Heading'
import Link from '../../../components/Link'

import * as Styled from "./styled"

const ListItems = ({ data }: { data: IRegulatorioList }): JSX.Element => (
  <Styled.LinkWrapper>
    <Styled.LinkTitle>
      <Heading headingLevel="h3" size="medium">
        {data.menu}
      </Heading>
    </Styled.LinkTitle>
    <Styled.LinkContainer>
      {data.links?.map(({ title, href, target }: IRegulatorioLinks, idx: number) => (
        <li key={`links-container-${idx}`}>
          <Link href={href} target={target}>
            {title}
          </Link>
        </li>
      ))}
    </Styled.LinkContainer>
  </Styled.LinkWrapper>
)

export default ListItems
