import React from 'react'

import ListItems from "../ListItems";

const ListMenu = ({ data, position }: IRegulatorioMenus): JSX.Element => (
  <div>
    {data.map((item: IRegulatorioList, idx: number): JSX.Element => <ListItems data={item} key={`list-items-${position}-${idx}`} />)}
  </div>
)

export default ListMenu
