import styled from 'styled-components'

export const LinkWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 36px;
  }
`

export const LinkTitle = styled.div`
  margin-bottom: 8px;
`

export const LinkContainer = styled.ul`
  padding: 8px 24px;
`
