import React from 'react'

import Heading from '../../components/Heading'
import ListMenu from './ListMenu'

import { financialLeftItems, financialRightItems, leftItems, rightItems } from './data'
import * as Styled from "./styled"

const RegulatoryMenus = (): JSX.Element => (
  <div className="container">
    <Styled.Wrapper>
      <Styled.HeadWrapper>
        <Heading headingLevel="h1" size="large" center>
          Regulatório
        </Heading>
      </Styled.HeadWrapper>
      <Styled.ContentWrapper>
        <ListMenu data={leftItems} position='left' />
        <ListMenu data={rightItems} position='right' />
      </Styled.ContentWrapper>
      <Styled.HeadWrapper>
        <Heading headingLevel="h1" size="large" center>
          Informações Financeiras
        </Heading>
      </Styled.HeadWrapper>
      <Styled.ContentWrapper>
        <ListMenu data={financialLeftItems} position='left' />
        <ListMenu data={financialRightItems} position='right' />
      </Styled.ContentWrapper>


    </Styled.Wrapper>
  </div>
)

export default RegulatoryMenus
